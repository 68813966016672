import React from 'react'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import Typography from '@material-ui/core/Typography'

import { useStaticQuery, graphql } from 'gatsby'

import { useThemeContext } from 'context/ThemeContext'
import Link from 'components/foundations/Link/Link'
import CustomImage from 'components/foundations/Image/CustomImage'
import SEO from 'components/elements/SEO/SEO'

export default function ConsultingList() {
  const ConsultingQuery = Object.values(
    useStaticQuery(graphql`
      query ConsultingLQuery {
        allSitePage(filter: { path: { regex: "/^/servizi/consulenza/" } }) {
          nodes {
            path
          }
        }
      }
    `).allSitePage.nodes,
  )

  const productURIs = ConsultingQuery.map((paths) => Object.values(paths).toString())

  function splitName(string) {
    return string.split('/')[3]
  }

  /*   function capitalise(string) {
    return string
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')
  } */

  const { isMobile } = useThemeContext()

  return (
    <>
      <SEO title="Consulenza" keywords={['SEO0', 'SEO1', 'SEO2']} />
      <section className="consulting closer">
        <h3 className="title">Consulenza</h3>
        <CustomImage filename={'consulting.jpg'} />
        <div className="consulting-grid">
          <div className="consulting-grid-list">
            <p style={{ marginBottom: '0.5rem' }}>CATEGORIE</p>
            <div className="separator" />
            <GridList cols={isMobile ? 1 : 1} cellHeight="auto" className="gridListConsulting">
              {productURIs.map((productURL) => (
                <GridListTile className="single-consulting" key={splitName(productURL)}>
                  <Link to={productURL}>
                    <Typography className="consulting-info" component="p">
                      {splitName(productURL).replace(/-/g, ' ')}
                    </Typography>
                  </Link>
                  <hr />
                </GridListTile>
              ))}
            </GridList>
          </div>
          <div className="consulting-grid-content">
            <p className="consulting-content-text">
              <strong>CONSULENZA: UN ANGELO SULLA VOSTRA SPALLA</strong>
              <br /> <br />
              Oggi il nostro core business comprende tutte le aree della consulenza su sicurezza del lavoro e sistemi di
              gestione e tutte le attività tipiche dell’ingegnere, dalla progettazione alla direzione lavori unitamente
              a medicina del lavoro e formazione.
              <br /> <br />
              ING GROUP è tra le poche realtà presenti sul mercato in grado di soddisfare globalmente le esigenze delle
              aziende nel settore della progettazione e della consulenza tecnica e della medicina del lavoro: la nostra
              mission è la soddisfazione del Cliente, essere il riferimento tecnico ed il supporto allo sviluppo
              commerciale dei nostri clienti/partner. Nel mercato attuale la crescita è soprattutto competitività, ed il
              team ING GROUP sa percepire le esigenze del cliente ed affiancarlo nel raggiungimento degli obiettivi
              prefissati insieme.
              <br /> <br />
              Disponiamo di tre sedi Milano – Napoli – Vicenza a copertura totale di tutto il territorio nazionale,
              assicurando sempre al cliente ASSENZA TOTALE DI COSTI DI TRASFERTA.
            </p>
          </div>
        </div>
      </section>
    </>
  )
}
